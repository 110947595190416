import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode: 'history', // 去掉#，需要路由模式改为history
  // base: '/dist/', // 这个配置也很重要，否则会出现页面空白情况
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('../components/Index'),
    },
    {
      path: '/Details',
      name: 'Details',
      component: () => import('../components/Details'),
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import('../components/Layout'),
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('../components/Contactus'),
    },
    {
      path: '/Aboutus',
      name: 'Aboutus',
      component: () => import('../components/Aboutus'),
    },
    {
      path: '/Appdownload',
      name: 'Appdownload',
      component: () => import('../components/Appdownload'),
    },
    {
      path: '/Qrcode',
      name: 'Qrcode',
      component: () => import('../components/Qrcode'),
    },
    {
      path: '/newQrcode',
      name: 'newQrcode',
      component: () => import('../components/newQrcode'),
    },
    {
      path: '/Application',
      name: 'Application',
      component: () => import('../components/Application'),
    },
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import('../components/Privacy'),
    },
    {
      path: '/Functiong',
      name: 'Functiong',
      component: () => import('../components/Functiong'),
    },
    {
      path: '/activity/autumnOct/index',
      name: 'autumnOctPC',
      component: () => import('../components/activity/autumnOct/index'),
    },
    {
      path: '/activity/autumnOct/wap',
      name: 'autumnOctWap',
      component: () => import('../components/activity/autumnOct/wap'),
    },
    {
      path: '/activity/startPage/index',
      name: 'startPc',
      component: () => import('../components/activity/startPage/index'),
    },
    {
      path: '/activity/voucher/index',
      name: 'voucherPc',
      component: () => import('../components/activity/voucher/index'),
    },
    {
      path: '/activity/czzj_6357/index',
      name: 'czzj_6357',
      component: () => import('../components/activity/czzj_6357/index'),
    },
    {
      path: '/activity/market/index',
      name: 'market',
      component: () => import('../components/activity/market/index'),
    },
    {
      path: '/activity/voucher/indexNew',
      name: 'voucherNewPc',
      component: () => import('../components/activity/voucher/indexNew'),
    },
    {
      path: '/activity/voucher/index2024',
      name: 'voucher2024Pc',
      component: () => import('../components/activity/voucher/index2024'),
    },
    {
      path: '/activity/czzj_6357/indexNew',
      name: 'czzj_6357_new',
      component: () => import('../components/activity/czzj_6357/indexNew'),
    },
    {
      path: '/activity/private-b',
      name: 'private-b',
      component: () => import('../components/activity/private-b/index.vue'),
    },
    {
      path: '/activity/traderCompetition',
      name: 'traderCompetition',
      component: () => import('../components/activity/traderCompetition/index.vue'),
    },
    {
      path: '/activity/voucher/indexFut',
      name: 'voucher2024Pc',
      component: () => import('../components/activity/voucher/indexFut'),
    },
  ],
})
