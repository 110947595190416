<template>
  <div id="box">
    <div :class="navBarFixed == true ? 'navBarWrap' :''">
      <div class="header">
      <div class="logo_img" @click="logoClick">
        <div style="display:flex; align-items: center; float:left; height: 60px;">
          <div class="header-logo">
            <img v-if="!navBarFixed" id="header-img" src="../../static/common/logo.png">
            <img v-if="navBarFixed" id="header-img" src="../../static/common/logowhite.png" alt="">
          </div>
        </div>
      </div>
      <div id="menu_index">
        <!-- web端菜单导航 -->
        <el-menu 
          v-if="windowWidth >= 500" 
          :default-active="activeIndex" 
          class="el-menu-demo" 
          menu-trigger="click" 
          text-color="#fafafa"
          style="height: 60px; border:0;" 
          mode="horizontal" 
          @select="handleSelect">
          <el-menu-item index="1" class="header-title">
            <span>首页</span>
            <img v-if="activeIndex == 1" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="2" class="header-title">
            <span>关于我们</span>
            <img v-if="activeIndex == 2" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="3" class="header-title">
            <span>交易细则</span>
            <img v-if="activeIndex == 3" src="../../static/index/selectRed.png" alt="">
          </el-menu-item>
          <el-menu-item index="4" class="header-title">
            <div class="special">
               <span >App下载</span>
            <img v-if="activeIndex == 4" src="../../static/index/selectRed.png" alt="">
            
            </div>
           
          </el-menu-item>
          <!-- <el-menu-item index="5" class="header-title">
            <span>联系客服</span>
            <img v-if="activeIndex == 5" src="../../static/index/selectRed.png" alt="">
          </el-menu-item> -->
        </el-menu>
        <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
        <el-menu 
          v-if="windowWidth < 500" 
          :default-active="activeIndex" 
          class="el-menu-demo" 
          menu-trigger="click" 
          text-color="#333"
          style="height: 65px; border:0;" 
          mode="horizontal" @select="handleSelectMobile">
          <el-submenu index="1" class="header-title mobile_menu">
            <template slot="title">{{currentMenu}}</template>
            <el-menu-item index="1" class="header-title">首页</el-menu-item>
            <el-menu-item index="2" class="header-title">关于我们</el-menu-item>
            <el-menu-item index="3" class="header-title">交易细则</el-menu-item>
            <el-menu-item index="4" class="header-title">App下载</el-menu-item>
            <el-menu-item index="5" class="header-title">联系客服</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <!-- <div v-if="seen" style="float: right; display: flex;align-items: center; height: 100%;">
        <el-button type="text" style="margin-right: 20px;" class="header-title" @click="login">登录</el-button>
        <el-button type="primary" class="header-title" @click="register" round>注册</el-button>
      </div> -->
    </div>
    <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="../assets/other/launch.png" style="height: 45px;width: 35px" />
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Layout",
    // props:['getSessinKey'],
    data() {
      return {
        activeIndex: '1',
        company: {
          qrcode: require("../../static/other/公众号二维码.jpg"),
        },
        scroll: '',
        timer: '',
        seen: true,
        currentMenu: '首页',
        navBarFixed: false,
        windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      };
    },
    created(){
      this.activeIndex = sessionStorage.getItem('key');
      // 传递key值给父组件App.vue
      this.$emit('getKey', this.activeIndex);
    },
    mounted() {
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.windowWidth = window.fullWidth; // 宽
        })()
      };
    },
    watch: {
      windowWidth (val) {
        let that = this;
        console.log("实时屏幕宽度：",val, that.windowWidth );
      }
    },
    methods: {
      handleSelect(key) {
        this.$emit('getKey', key);
        sessionStorage.setItem('key',key);
        // this.getSessinKey(key);
        switch (key) {
          case '1':
            this.activeIndex = key;
            this.$router.push({
              name: "Index"
            });
            break;
          case '2':
            this.activeIndex = key;
            this.$router.push({
              name:'Aboutus'
            })
            break;
          case '3':
            this.activeIndex = key;
            this.$router.push({
              name: "Details"
            });
            break;
          case '4':
            this.activeIndex = key;
            this.$router.push({
              name: "Appdownload"
            });
            break;
          case '5':
            this.activeIndex = key;
            this.$router.push({
              name: "Index"
            });
            break;
        }
      },
      handleSelectMobile(key) {
        switch (key) {
          case '1':
            this.$router.push({
              name: "Index"
            });
            break;
          case '2':
            this.$router.push({
              name:'Aboutus'
            })
            break;
          case '3':
            this.$router.push({
              name: "Details"
            });
            // window.location.hash = "#交易细则";
            break;
          case '4':
            this.$router.push({
              name: "Appdownload"
            });
            // window.location.hash = "#App下载";
            break;
          case '5':
            this.$router.push({
              name: "Index"
            });
            // window.location.hash = "#联系客服";
            break;
        }
      },
      handleScroll() {
        this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        if(this.scroll > 600) { //判断滚动后高度超过400px,就显示
          // this.$refs.btn.style.display = 'block';
        } else {
          // this.$refs.btn.style.display = 'none'
        }
        if(this.scroll > 90 ){
          this.navBarFixed = true;
        }else{
          this.navBarFixed = false;
        }
      },
      backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer)
          }
        }, 10)
      },
      login() {
        window.open('https://admin.fastice-tech.com/login')
      },
      register() {
        window.open('https://admin.fastice-tech.com/registe')
      },
      logoClick() {
        this.$router.push({
          name: "Index"
        });
        this.currentMenu = '首页';
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
  }
</script>

<style lang="less" scoped>
  #box{
    width: 100%;
    margin: 0 auto;
    background-color: #121212;
  }
  .logo_img {
    float: left;
    cursor: pointer;
  }

  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 30px;
    cursor: pointer
  }

  .header-title {
    font-size: 1em;
  }

  .company_name {
    font-weight: normal;
    font-size: 1em;
  }

  .footer_font {
    font-size: 1em;
  }

  #menu_index {
    height: 100%;
  }
  /deep/ .el-menu--horizontal{
    background-color: #121212;
  }
  /deep/ .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background-color: #121212;
  }

  .header {
    margin: auto;
    width: 1135px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #header-img {
    width: 115px;
    height: 27px;
    float: left;
    margin: 0 auto;
  }

  .header-logo {
    width: 115px;
    height: 27px;
  }

  .header-important {
    float: left;
    margin-left: 6px;
    align-self: center;
  }

  .footer {
    display: flex;
    align-items: center;
    margin: 10px auto;
    width: 90%;
  }
  /deep/ .el-menu--horizontal>.el-menu-item.is-active{
    color: #fafafa;
    border-bottom:0px solid #121212;
  }
  /deep/ .el-menu-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .el-menu-item img{
    position: absolute;
    bottom:0px;
  }
  /deep/ .el-menu.el-menu--horizontal { // 去掉element-ui导航栏底部边框线
     border-bottom: none;
  }
  .header-title:focus {
    outline: none;
  }
  .navBarWrap {
    width: 100%;
    position:fixed;
    top:0;
    left: 50%;
    transform: translate(-50%,0);
    z-index:999;
    background-color: #121212;
    // transition: all .5s;
  }
  .special{
    position: relative;
    ul{
      width: 275px;
      position: absolute;
      top:50px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 20px 30px 20px;
      border-radius: 10px;
      box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
      display: none;
      li{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width:100px;
          height: auto;
          position: unset;
        }
        span{
          margin-top: 5px;
          color: #000;
          line-height: 29px;
        }
      }
      li:nth-child(1){
        margin-right: 55px;
      }
    }
  }
  .special:hover{
     .codeBox{
        display: flex;
      }
  }
</style>