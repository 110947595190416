//配置文件;
// (function($) {
//   var host = window.location.host;
//   var defaults = {
//     api: '/api/m/',
//     domain: '.jf24k.hk',
//     www: 'https://www.jf24k.hk/',
//     m: 'https://m.jf24k.com.hk/',
//     apiNew: '/apis/',
//     maidian: 'https://crm.etescape.com:12343/maidian/scada/collect'
//   };
//   if (host.indexOf('test') == 0 || host.indexOf('localhost') > -1 || host.indexOf('192.168') > -1 || host.indexOf('.lan') > -1) {
//     defaults = $.extend(defaults, {
//       www: 'http://www.jf24k.lan/',
//       m: 'http://m.jf24k.lan/',
//       api: '/api/m/',
//       Ant: 'http://192.168.0.247:8115/',
//       maidian: 'http://192.168.0.94:8080/scada/collect'
//     });
//     if (host.indexOf('localhost') > -1 || host.indexOf('192.168') > -1 || host.indexOf('.lan') > -1) {
//       defaults.www = '/';
//       defaults.domain = window.location.hostname;
//     }
//   }
//   $.config = defaults;
// })(jQuery);
function isMobileBrowser() {
  
  return /Mobi|Android/i.test(navigator.userAgent);
}

if (isMobileBrowser()) {
  var domain = window.location.hostname;

  // 获取一级域名
  var firstLevelDomain = domain.replace(/(.*?)(\.|$)/, 'm$2');

  // 构建新的URL
  var newURL = window.location.protocol + '//' + firstLevelDomain + window.location.pathname + window.location.hash;

  // 重定向到新的URL
  window.location.href = newURL;
}
function bro() {
  var broName = '其他';
  var userAgent = window.navigator.userAgent;

  //FireFox
  if (userAgent.indexOf('Firefox') != -1) {
    broName = 'FireFox浏览器'
  }
  //Edge
  if (userAgent.indexOf('Edge') != -1) {
    broName = 'Edge浏览器'
  }
  //IE浏览器
  if (userAgent.indexOf('NET') != -1 && userAgent.indexOf("rv") != -1) {
    broName = 'IE浏览器';
  }
  //360极速模式可以区分360安全浏览器和360极速浏览器
  if (userAgent.indexOf('WOW') != -1 && userAgent.indexOf("NET") < 0 && userAgent.indexOf("Firefox") < 0) {
    if (navigator.javaEnabled()) {
      broName = '360安全浏览器-极速模式';
    } else {
      broName = '360极速浏览器-极速模式';
    }
  }
  //360兼容
  if (userAgent.indexOf('WOW') != -1 && userAgent.indexOf("NET") != -1 && userAgent.indexOf("MSIE") != -1 && userAgent.indexOf("rv") < 0) {
    broName = '360兼容模式';
  }
  //Chrome浏览器
  if (userAgent.indexOf('AppleWebKit') != -1) {
    broName = 'Chrome浏览器'
  }
  return broName
}

function getHashParams(type) {
  var result = {};
  var params = (window.location.hash.split('?')[1] || '').split('&');
  var paramParts = [];
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      paramParts = params[param].split('=');
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
    }
  }
  if (type) {
    return result[type];
  }
  return result;
}
// 获取渠道
export function appCode() {
  const referrer = document.referrer.split('?')[1]
  const obj = {}
  if (referrer) {
    const referrArr = referrer.split('&');
    for (let i = 0; i < referrArr.length; i++) {
      obj[referrArr[i].split('=')[0]] = referrArr[i].split('=')[1]
    }
  }
  const nowParams = location.search.replace(/^\?/, '')
  const objParams = nowParams.split('&');
  const obj2 = {}
  for (let i = 0; i < objParams.length; i++) {
    obj2[objParams[i].split('=')[0]] = objParams[i].split('=')[1]
  }
  return Object.assign({}, obj, obj2)
}
export function maidian() {
  // console.log(arguments[3]);
  var paramsMd = appCode()
  var host = window.location.host;
  if (host.indexOf('ibmuc') > -1) {
    return
  }
  try {
    var params1 = arguments[0] ? arguments[0] : undefined;
    var params2 = arguments[1] ? arguments[1] : undefined;
    var clientid = '';
    var channelname = '';


    if (getHashParams('clientid')) {
      localStorage.setItem('clientid', getHashParams('clientid'))
      clientid = localStorage.getItem('clientid')
    } else if (localStorage.getItem('clientid')) {
      clientid = localStorage.getItem('clientid')
    } else {
      clientid = (Math.random() * 10000000).toString(16).substr(0, 4) + '_' + (new Date()).getTime() + '_' + Math.random().toString().substr(2, 5);
      localStorage.setItem('clientid', clientid)
    }

    if (document.referrer === "") {
      channelname = '手动输入'
    } else if (document.referrer.indexOf('rlc') > 0) {
      channelname = '站内跳转'
    } else {
      var ref = document.referrer.match("(?<=://)[a-zA-Z\.0-9:]+(?=\/)") || ['other']
      channelname = ref[0].split('.')
      channelname = channelname.length > 1 ? channelname[1] : channelname[0]
    }

    // 咨询数据多次限制
    if (params1 && params1.eventname === 'z2') {
      if (localStorage.getItem('z2T') && ((new Date().getTime() - localStorage.getItem('z2T')) / 1000) < 1800) {
        return
      }
      localStorage.setItem('z2T', new Date().getTime())
    }

    var userInfo = localStorage.getItem('I') ? JSON.parse($.base64.decode(localStorage.getItem('I'))) : {};
    var listType = localStorage.getItem('ListType') ? localStorage.getItem('ListType') : 1;
    // var usertype = 2;

    // if ((getHashParams('source') && getHashParams('source') == 'ios') || (Cookies.get('source') && Cookies.get('source') == 'ios')) {
    //   usertype = 3
    // } else if ((getHashParams('source') && getHashParams('source') == 'android') || (Cookies.get('source') && Cookies.get('source') == 'android')) {
    //   usertype = 4
    // }

    var params = {
      sourceid: 1,
      usertype: 1,
      clientid: clientid,
      eventtime: new Date().getTime(),
      detail: {
        channelname: channelname,
        version: bro(),
        // version:navigator.userAgent,
        backurl: arguments[3] == 'indexMaidian' ? '' : location.origin + '/#' + arguments[2].fromPath,
        currenturl: arguments[3] == 'indexMaidian' ? window.location.href : location.origin + '/#' + arguments[2].toPath,
        account: userInfo.account,
        accounttype: +listType + 1,
        mobile: userInfo.mobile || userInfo.jfUser ? userInfo.jfUser.mobile : undefined || undefined,
        medium: paramsMd['utm_medium'] || undefined, //媒介
        keyworld: paramsMd['utm_term'] || undefined, //关键字
        plan: paramsMd['utm_campaign'] || undefined, //计划
        unit: paramsMd['utm_content'] || undefined, //单元
        packageid: paramsMd['v'] || undefined,
      }
    }
    if (params1) {
      $.extend(params, params1)
    }
    if (params2) {
      $.extend(params.detail, params2)
    }

    $.ajax({
      url: 'https://crm.etescape.com:12343/maidian/scada/collect',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify([params])
    })

  } catch (error) {
    console.log(error);
  }
}